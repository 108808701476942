import commonActions from './actions';

const initialState = {
    toast: {
        show: false,
        message: '',
        variant: '',
    },
    totalAssetsCount: 20,
    loadedAssetsCount: 0,
};

const CommonReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case commonActions.OPEN_TOAST:
            return {
                ...state,
                toast: {
                    show: true,
                    message: action.payload.message,
                    variant: action.payload.variant,
                },
            };
        case commonActions.CLOSE_TOAST:
            return {
                ...state,
                toast: {
                    show: false,
                    message: '',
                    variant: '',
                },
            };

        case commonActions.SET_TOTAL_ASSETS_COUNT:
            return {
                ...state,
                totalAssetsCount: action.payload.count,
            };
        case commonActions.ADD_LOADED_ASSETS_COUNT:
            return {
                ...state,
                loadedAssetsCount: state.loadedAssetsCount + 1,
            };
        default:
            return state;
    }
};

export default CommonReducer;
