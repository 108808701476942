interface SETTINGS_TYPE {
    signatureMessage: string;
}

const SETTINGS: SETTINGS_TYPE = {
    signatureMessage: 'Welcome to Metal Heads NFT! I am signing my one-time nonce: {{nonce}}',
};

const PRELOAD_IMAGES: string[] = [
    require('assets/img/logo_v1.png'),
    require('assets/img/icon_discord.svg'),
    require('assets/img/icon_twitter.svg'),
    require('assets/img/icon_instagram.svg'),
    require('assets/img/clarity_menu-line.svg'),
    require('assets/img/mdi_wallet.svg'),
    require('assets/gif/arrow_down_animation.gif'),
    require('assets/img/mdi_volume-high.svg'),
    require('assets/img/mdi_volume-off.svg'),
    require('assets/img/vapour_tiger.jpg'),
    require('assets/img/icon_discord_black.svg'),
    require('assets/img/icon_twitter_black.svg'),
    require('assets/img/icon_insta_black.svg'),
    require('assets/img/clarity_close-line.svg'),
    require('assets/img/unclean_bishop.jpg'),
    require('assets/img/eisen_guard.jpg'),
    require('assets/img/ashikaga_shogun.jpg'),
    require('assets/img/Avatar_01.jpg'),
    require('assets/img/Avatar_02.jpg'),
    require('assets/img/Avatar_03.jpg'),
    require('assets/img/Avatar_04.jpg'),
    require('assets/img/Avatar_05.jpg'),
    require('assets/img/Avatar_06.jpg'),
    require('assets/img/Avatar_07.jpg'),
    require('assets/img/icon_artstation_white_no_frame.svg'),
    require('assets/img/icon_youtube_white_no_frame.svg'),
    require('assets/img/icon_metalheads_white_no_frame.svg'),
    require('assets/img/icon_instagram_white_no_frame.svg'),
    require('assets/img/icon_twitter_white_no_frame.svg'),
    require('assets/img/bg_loading_v2.png'),
    require('assets/img/meta_mask_icon.svg'),
    require('assets/img/reserve_page_compressed.jpeg'),

]

export { SETTINGS, PRELOAD_IMAGES };
