import Styled from 'styled-components';

const LoadingWrapper = Styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
`;

const PageWrapper = Styled.div`
    position: relative;
    background: rgba(0, 0, 0, 1);
    min-height: 100vh;
`;

const ContentBodyWrapper = Styled.div`
    position: relative;
    max-width: 1200px;
    // min-height: 80vh;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    // padding-bottom: 64px;

    @media only screen and (max-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

export { LoadingWrapper, PageWrapper, ContentBodyWrapper };
