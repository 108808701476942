const commonActions = {
    OPEN_TOAST: 'OPEN_TOAST',
    CLOSE_TOAST: 'CLOSE_TOAST',
    SET_TOTAL_ASSETS_COUNT: 'SET_TOTAL_ASSETS_COUNT',
    ADD_LOADED_ASSETS_COUNT: 'ADD_LOADED_ASSETS_COUNT',

    openToast: (message: string, variant: string): any => {
        return {
            type: commonActions.OPEN_TOAST,
            payload: {
                message,
                variant,
            },
        };
    },

    closeToast: (): any => {
        return {
            type: commonActions.CLOSE_TOAST,
        };
    },

    setTotalAssetsCount: (count: number): any => {
        return {
            type: commonActions.SET_TOTAL_ASSETS_COUNT,
            payload: {
                count,
            },
        };
    },

    addLoadedAssetsCount: (): any => {
        return {
            type: commonActions.ADD_LOADED_ASSETS_COUNT,
        };
    },
};

export default commonActions;
