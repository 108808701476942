import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { LoadingWrapper } from 'styles/style';
import ReactGA from 'react-ga';
import LoadingBar from 'components/LoadingBar';
import { PRELOAD_IMAGES } from 'config/settings';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from 'redux/common/actions';
import { RootState } from 'redux/rootReducers';

const LandingPage = lazy(() => import('pages/LandingPage'));
const PublicSaleConnectWallet = lazy(() => import('pages/PublicSaleConnectWallet'));
const PublicSaleMint = lazy(() => import('pages/PublicSaleMint'));
const PresaleConnectWallet = lazy(() => import('pages/PresaleConnectWallet'));
const PresaleMint = lazy(() => import('pages/PresaleMint'));
const PresaleWallet = lazy(() => import('pages/PresaleConnect'));
const ComingSoon = lazy(() => import('pages/ComingSoon'));
const ReservePage = lazy(() => import('pages/ReservePage'));
const QuizPage = lazy(() => import('pages/QuizPage'));
const ReservedPage = lazy(() => import('pages/ReservedPage'));

// Initialize google analytics and track every page
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const NotFound = () => {
    return <Redirect to="/" />;
};

const Routes = (): JSX.Element => {
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const setTotalAssetsCount = (count: number) => dispatch(commonActions.setTotalAssetsCount(count));
    const addLoadedAssetsCount = () => dispatch(commonActions.addLoadedAssetsCount());
    const isLoggedIn = useSelector(({ auth }: RootState) => auth.login);

    // Preload images
    useEffect(() => {
        // If is landing page "/" then there's a video that will append in. There need + 1 here
        setTotalAssetsCount(pathname === '/' ? PRELOAD_IMAGES.length + 1 : PRELOAD_IMAGES.length);

        const images: any[] = [];

        PRELOAD_IMAGES.forEach((image, index) => {
            images[index] = new Image();
            images[index].src = image;
            addLoadedAssetsCount();
        });
    }, []);

    // Scroll to top when change path
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const checkIsGuest = (RouteComponent: any, redirectTo = '/reserve') => {
        if (isLoggedIn) {
            return <Redirect to={redirectTo} />
        }
        return <RouteComponent />;
    }

    const checkIsAuthenticated = (RouteComponent: any) => {
        if (!isLoggedIn) {
            return <Redirect to="/" />
        }
        return <RouteComponent />;
    }

    return (
        <Suspense fallback={<LoadingWrapper>{/* <LoadingBar /> */}</LoadingWrapper>}>
            <Switch>
                <Route exact path="/" component={LandingPage} />

                <Route exact path="/presale" component={PresaleWallet} />

                <Route exact path="/coming-soon" component={ComingSoon} />

                {/* Guest only */}
                {/* <Route exact path="/public-connect" render={() => checkIsGuest(PublicSaleConnectWallet, '/reserve')} /> */}
                {/* <Route exact path="/presale-connect" render={() => checkIsGuest(PresaleConnectWallet, '/presale-mint')} /> */}

                {/* Auth only */}
                {/* <Route exact path="/reserve" render={() => checkIsAuthenticated(ReservePage)} /> 
                <Route exact path="/quiz" render={() => checkIsAuthenticated(QuizPage)} />
                <Route exact path="/reserved" render={() => checkIsAuthenticated(ReservedPage)} />
                <Route exact path="/public-mint" render={() => checkIsAuthenticated(PublicSaleMint)} /> */}
                {/* <Route exact path="/presale-mint" render={() => checkIsAuthenticated(PresaleMint)} /> */}
                
                {/* Route Not Found */}
                <Route exact path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );
};



export default Routes;
